<template>
  <div>
    <MasterHeader />
    <!-- <sign-up-modal v-if="hasNewSignupFlow"/> -->
    <div class="container">
        <div v-if="user.role != 'none'" class="container">
          <div><strong>role:</strong> {{ user.role }}</div>
          <div><strong>email:</strong> {{ user.email }}</div>
          <div><strong>status:</strong> {{ user.status }}</div>
          <h3>Personal</h3>
          <div><strong>firstname:</strong> {{ user.firstName }}</div>
          <div><strong>lastname:</strong> {{ user.lastName }}</div>
          <div><strong>phone number:</strong> {{ user.phoneNumber }}</div>
          <div><strong>interests:</strong> {{ user.interests }}</div>

          <h3>Student</h3>
          <div><strong>graduating semester:</strong> {{ user.graduatingSemester }}</div>
          <div><strong>country:</strong> {{ user.country }}</div>
          <div><strong>institution:</strong>{{ user.institution.id }} {{ user.institution.name }}</div>
          <div><strong>faculty:</strong> {{ user.faculty }}</div>
          <div><strong>degree:</strong> {{ user.degree }}</div>
          <div><strong>area:</strong> {{ user.area }}</div>

          <h3>Professional</h3>
          <div><strong>company:</strong> {{ user.company }}</div>
          <div><strong>jobTitle:</strong> {{ user.jobTitle }}</div>
          <br>
          <router-link to="/user/welcome" class="btn btn-primary btn-sm"><i class="fas fa-user-edit"></i> Edit</router-link>
          <br><br>
          {{user}}

        </div>
        <div v-else>
          user.role == none
        </div>     
        
      </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import MasterHeader from '@/components/MasterHeader.vue'
import SignUpModal from '../../components/signUpModal.vue'
import { base64ToString } from '../../utils/utils'

export default {
    name: 'user-profile',
    components: {
        MasterHeader,
        //SignUpModal,
    },
    computed: {
      ...mapState(['user']),
    hasNewSignupFlow(){
      const vm = this
      const isValid = vm.$store.getters.currentProgress >= 0 ? true : false
      const isStudent = vm.$store.getters.role === 'Student'
        if (!isValid && isStudent) {
          localStorage.setItem('notSignedUp', 1);
        }
        if (isValid) {
          localStorage.removeItem('notSignedUp');
        }
      return true
    },
    },
  created() {
    // const userType = localStorage.getItem('i_am')
    // const userVerified = localStorage.getItem('u-verified')
    let userType = localStorage.getItem('i_am')
    userType = base64ToString(userType)
    let userVerified = localStorage.getItem('u-verified')
    if (userVerified == 0 && userType == 'student') {
      this.$router.push('/user/student-welcome')
    }
    if (userVerified == 0 && userType == 'professional') {
      this.$router.push('/user/welcome')
    }
  },
    methods: {

    }
}
</script>

<style>

</style>